import React from "react";


export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
          At CodeCrafters, we offer a comprehensive range of services designed to elevate your online presence. Our <strong>Web Development</strong> solutions ensure robust and scalable websites tailored to your unique business needs. With our <strong> E-Commerce Solutions</strong>, we create powerful online stores that drive sales and enhance customer engagement. Our <strong>Responsive Design</strong> ensures optimal viewing experiences across all devices, while <strong>SEO Optimization</strong> enhances your site's visibility on search engines. We also provide <strong>Website Maintenance</strong> to keep your site secure and up-to-date, and our <strong>Graphic Design</strong> services craft stunning visuals that resonate with your audience. Partner with us to unlock your digital potential!
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-lg-4 col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
